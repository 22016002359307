import { useState } from "react"
import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import TextField from "@mui/material/TextField"

const format = (number) => {
    if (number === Infinity) {
        return "Infinity"
    } else if (number >= 1000) {
        return parseInt(number).toLocaleString(undefined)
    }
    return number.toLocaleString(undefined, { maximumSignificantDigits: 3 })
}

const protectionFactor = (percentage) => {
    return 1 / (1 - percentage / 100)
}

function App() {
    const [percA, setPercA] = useState(null)
    const [percB, setPercB] = useState(null)

    const handleChange = (text, setter) => {
        const value = parseFloat(text)
        if (!isNaN(value) && value >= 0 && value <= 100) {
            setter(value)
        } else {
            setter(null)
        }
    }

    const ratio =
        percA === null || percB === null
            ? null
            : protectionFactor(percA) / protectionFactor(percB)
    let messageArr = [`Protection from A (${percA}%) is`, null, `B (${percB}%)`]
    let message = null
    if (percA !== null && percB !== null) {
        if (ratio > 1) {
            messageArr[1] = `${format(ratio)} TIMES BETTER THAN`
        } else if (ratio < 1) {
            messageArr[1] = `${format(1 / ratio)} TIMES WORSE THAN`
        } else {
            messageArr[1] = "is EQUAL to"
        }
        message = (
            <Stack
                direction="row"
                spacing={1}
                sx={{ justifyContent: "center" }}
            >
                {messageArr.map((el, i) => {
                    const variant = i === 1 ? "h5" : null
                    const fontWeight = i === 1 ? "bold" : null
                    return (
                        <Typography
                            key={i}
                            variant={variant}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                fontWeight,
                            }}
                        >
                            {el}
                        </Typography>
                    )
                })}
            </Stack>
        )
    }

    const Chart = () => {
        const colorBar = "steelblue"
        const protectionFactors = [
            protectionFactor(percA),
            protectionFactor(percB),
        ]
        const width = 400
        const height = 400
        const widthBar = 80
        const yAxisMargin = 30
        const maxVal = Math.max(...protectionFactors)
        const x = [width * 0.25 - widthBar / 2, width * 0.75 - widthBar / 2]
        const y = [
            (height * protectionFactors[0]) / maxVal,
            (height * protectionFactors[1]) / maxVal,
        ]

        return (
            <div>
                <svg
                    height={height}
                    width={width}
                    viewBox={`0 0 ${width} ${height + yAxisMargin}`}
                >
                    <rect
                        width={widthBar}
                        height={y[0]}
                        transform={`translate(${x[0]} ${
                            height * (1 - protectionFactors[0] / maxVal)
                        })`}
                        fill={colorBar}
                    />
                    <text
                        transform={`translate(${x[0] + widthBar / 2} ${
                            height + yAxisMargin
                        })`}
                        textAnchor="middle"
                    >
                        A
                    </text>
                    <rect
                        width={widthBar}
                        height={y[1]}
                        transform={`translate(${x[1]} ${
                            height * (1 - protectionFactors[1] / maxVal)
                        })`}
                        fill={colorBar}
                    />
                    <text
                        transform={`translate(${x[1] + widthBar / 2} ${
                            height + yAxisMargin
                        })`}
                        textAnchor="middle"
                    >
                        B
                    </text>
                </svg>
            </div>
        )
    }

    return (
        <Box>
            <Stack sx={{ p: 1, alignItems: "center" }}>
                <Typography sx={{ justifyContent: "center" }}>
                    Percent Efficacies
                </Typography>
                <Stack
                    direction="row"
                    sx={{ p: 1, gap: 6, justifyContent: "center" }}
                >
                    <Stack>
                        <TextField
                            id="percentage-a"
                            label="% A"
                            variant="outlined"
                            onChange={(event) =>
                                handleChange(event.target.value, setPercA)
                            }
                            inputProps={{
                                sx: {
                                    textAlign: "center",
                                },
                            }}
                            sx={{ width: 80 }}
                        />
                        <Typography
                            sx={{ display: "flex", justifyContent: "center" }}
                        >
                            {percA === null
                                ? ""
                                : `PF: ${format(protectionFactor(percA))}`}
                        </Typography>
                    </Stack>
                    <Stack>
                        <TextField
                            id="percentage-b"
                            label="% B"
                            variant="outlined"
                            onChange={(event) =>
                                handleChange(event.target.value, setPercB)
                            }
                            inputProps={{
                                sx: {
                                    textAlign: "center",
                                },
                            }}
                            sx={{ width: 80 }}
                        />
                        <Typography
                            sx={{ display: "flex", justifyContent: "center" }}
                        >
                            {percB === null
                                ? ""
                                : `PF: ${format(protectionFactor(percB))}`}
                        </Typography>
                    </Stack>
                </Stack>
                {message}
                <Chart />
            </Stack>
        </Box>
    )
}

export default App
